/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"



// const BioSmall = () => {
//   return (
//     <div className="card-container">
//       <span className="pro">PRO</span>
//       <img
//         className="round"
//         src="https://randomuser.me/api/portraits/women/79.jpg"
//         alt="user"
//       />
//       <h3>Ricky Park</h3>
//       <h6>New York</h6>
//       <p>
//         User interface designer and <br /> front-end developer
//       </p>
//       <div className="buttons">
//         <button className="primary">Message</button>
//         <button className="primary ghost">Following</button>
//       </div>
//       <div className="skills">
//         <h6>Skills</h6>
//         <ul>
//           <li>UI / UX</li>
//           <li>Front End Development</li>
//           <li>HTML</li>
//           <li>CSS</li>
//           <li>JavaScript</li>
//           <li>React</li>
//           <li>Node</li>
//         </ul>
//       </div>
//     </div>
//   )
// };





const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
            linkedin
            medium1
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social


  return (

    <div className="bio">
      <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["auto", "webp", "avif"]}
        src="../images/profile-pic.jpg"
        width={50}
        height={50}
        quality={95}
        alt="Profile picture"
      />
      {author?.name && (


        <p>
          <br />{author?.summary || null}

          {/* <p>Written by <strong>{author.name}</strong>
          </p> */}

          {` `}
          {/* <a href={`https://twitter.com/${social?.twitter || ``}`}>
            You should follow them on Twitter
          </a> */}
          <a href={`https://medium.com/${social?.medium1 || ``}`}>
            Please follow me on medium.com
          </a>
          {` `}
          <a href={`https://www.linkedin.com/in/${social?.linkedin || ``}/`}>
            or contact me in linkedin
          </a>
        </p>
      )}
    </div>
  )
}

export default Bio
