/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"







const BioCard = () => {
  // const data = useStaticQuery(graphql`
  //   query BioCard {
  //     site {
  //       siteMetadata {
  //         author {
  //           name
  //           summary
  //         }
  //         social {
  //           twitter
  //         }
  //       }
  //     }
  //   }
  // `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  // const author = data.site.siteMetadata?.author
  // const social = data.site.siteMetadata?.social


  return (
    <div className="card-container">

      {/* <span className="pro">PRO</span> */}
      {/* <img
        className="round"
        src="../images/profile-pic.png"
        alt="user"
      /> */}
      <div className="image-wrapper">
        <StaticImage
          className="round"
          layout="fixed"
          formats={["auto", "webp", "avif"]}
          src="../images/profile-pic.jpg"
          width={150}
          height={150}
          quality={95}
          alt="Profile picture"
        />
      </div>



      <h3>Leandro Merli</h3>
      <h6>From Argentina, Located in Ecuador</h6>
      <p>

        I'm a full stack developer but If I have to choose....<br />
        I'd rather be a frontend developer<br />
        I'm working now on projects with Angular, Node.JS, MongoDB, Amazon Web Services (AWS), Ionic and Serverless Framework, but I always want to learn new things ;)

      </p>
      <div className="buttons">
        <a className="primary" href="https://www.linkedin.com/in/leandro-merli-00980515a/" target="_blank">Linkedin</a>
        <a className="primary ghost" href="https://github.com/leandromerli" target="_blank">GitHub</a>
      </div>
      <div className="skills">
        <h6>Skills</h6>
        <ul>
          <li>Angular</li>
          <li>HTML</li>
          <li>Node.JS</li>
          <li>AWS</li>
          <li>CSS</li>
          <li>JavaScript</li>
          <li>Ionic</li>
          <li>Mongo.DB</li>
          <li>Express</li>
          <li>Gatsby</li>
          <li>React</li>
          <li>Wordpress</li>
        </ul>
      </div>
    </div>
  )


}

export default BioCard
